footer{
    padding:4rem;
    background: #fff;
    display: grid;
    grid-template-columns: repeat(3,1fr);
}
ul{
    padding: 0;
}
iframe{
    border: none;
}
.subtitle__footer{
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #222;
    font-size: 1.3rem;
    padding-bottom: 1rem;
}
.content__info-contact{
    margin-bottom: 2rem;
}
.content__columns-footer p{
    width: 350px;
}
.content__columns-footer p, ul,a{
    color: #303030;
    font-family: "Poppins", sans-serif;
    transition: .3s ease;
}
.content__columns-footer .link__footer a {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    background-color: transparent;
    transition: .3s ease;
}
.content__columns-footer ul div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
}
.content__columns-footer .link__footer a:hover {
    background-color: #293789;
    border: 1px solid #fff;
}
.content__columns-footer .link__footer a svg{
    font-size: 1.8rem;
}
.content__columns-footer .link__footer a svg:hover{
    color: #fff;
}
.content__columns-footer ul,li{
    padding-bottom: 1rem;
}
.text__footer{
    background-color: #293789;
}
.text__footer p{
    text-align: center;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    padding: 1rem 0;
}
/* @media only screen and (max-width:1250px){
    footer{
        grid-template-columns: repeat(3,1fr);
    }
} */
@media only screen and (max-width:1024px){
    footer{
        grid-template-columns: repeat(2,1fr);
    }
}
@media only screen and (max-width:768px){
    footer{
        grid-template-columns: 1fr;
        padding: 2rem 1rem;
    }
}
@media only screen and (max-width:425px){
    .content__columns-footer p{
        width: auto;
    }
}