/*
    ** All elements border-box
*/
*,
*:before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/*
    ** Body main style
*/
body {
  scroll-behavior: smooth;
  font-family: 'Montagu Slab', serif;
}
ul li{
  list-style: none;
}
a{
  text-decoration: none;
}
  [id] {
    scroll-margin-top: 15ex;
}