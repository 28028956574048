.container__hero img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  position: relative;
}
.container__hero .content__hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.content__hero h3 {
  color: #fff;
  text-align: center;
  font-family: "Ruthie", cursive;
  font-size: 45px;
  font-weight: 400;
  line-height: 45px;
  margin-bottom: 0;
  text-align: center;
}
.content__hero h1 {
  color: #ffffff;
  font-family: "Abril Fatface", cursive;
  font-size: 85px;
  font-weight: 400;
  line-height: 100px;
  margin-top: 2rem;
  /* text-transform: capitalize; */
}
.container__hero .content__hero a {
  width: 270px;
  border-radius: 50px !important;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 0;
  padding: 0;
  line-height: 49px;
  height: 51px;
  letter-spacing: 0.28px;
  border: 2px solid #ffffff;
  transition: 0.4s ease;
  margin-top: 2rem;
}
.container__hero .content__hero a:hover {
  background-color: #fff;
  color: #222;
}
@media only screen and (max-width:768px){
  .content__hero h1{
    font-size: 45px;
    line-height: 60px;
    text-align: center;
  }
  .content__hero h3{
    font-size: 25px;
  }
}
@media only screen and (max-width:500px){
  .content__hero h1{
    font-size: 30px;
    line-height: 40px;
  }
  .content__hero h3{
    font-size: 20px;
  }
}